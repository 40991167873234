// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import colors from "vuetify/lib/util/colors";
import { themePreset } from '@/themeConfig'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
  // breakpoint: themePreset.breakpoint,
  theme: themePreset.theme,
  rtl: themePreset.rtl,
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
})
