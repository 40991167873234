// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  linkExactActiveClass: 'active',
  // base: process.env.BASE_URL,
  // scrollBehavior: (to, from, savedPosition) => {
  //   if (to.hash) return { selector: to.hash }
  //   if (savedPosition) return savedPosition

  //   return { x: 0, y: 0 }
  // },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/base/Index.vue'),

      children: [

        {
          path: '/',
          name: 'Home',
          component: () => import('@/views/home/Home.vue'),
           meta: { title: 'Início' },

        },
        {
          path: '/home-two',
          name: 'Home Two',
          component: () => import('@/views/home/HomeTwo.vue'),
        },
        {
          path: '/home-three',
          name: 'Home Three',
          component: () => import('@/views/home/HomeThree.vue'),
        },
        {
          path: '/home-four',
          name: 'Home Four',
          component: () => import('@/views/home/HomeFour.vue'),
        },
        {
          path: '/portal/pages/auth/recuperar/senha',
          name: 'ResetPassword',
          component: () => import('@/views/pages/Portal/Auth/ResetPassword.vue'),
          meta: { title: 'Recuperar Senha' },

        },
        {
          path: '/portal/pages/auth/login',
          name: 'PortalLogin',
          component: () => import('@/views/pages/Portal/PortalLogin.vue'),
           meta: { title: 'Iniciar sessão' },

        },
        {
          path: '/portal/pages/auth/register',
          name: 'PortalRegister',
          component: () => import('@/views/SignUp.vue'),
          meta: { title: 'Criar Conta' },

        },
        {
          path: '/portal',
          name: 'PortalIndex',
          component: () => import('@/views/pages/Portal/PortalIndex.vue'),
        },
        {
          path: '/components',
          name: 'Components',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: '/sale-page-one',
          name: 'SalePageOne',
          component: () => import('@/views/pages/SalePageOne.vue'),
        },
        {
          path: '/sale-page-two',
          name: 'SalePageTwo',
          component: () => import('@/views/pages/SalePageTwo.vue'),
        },
        {
          path: '/all-vendors',
          name: 'AllVendors',
          component: () => import('@/views/vendor/AllVendors.vue'),
        },

        // @Zudas

        {
          path: '/portal/pages/carrinho',
          name: 'CARRINHO',
          component: () => import('@/views/pages/Portal/CarrinhoIndex.vue'),
        },
        {
          path: '/portal/pages/perguntas-frequentes',
          name: 'FAQS',
          component: () => import('@/views/pages/Portal/Faqs/FaqsIndex.vue'),
           meta: { title: 'Perguntas Frequentes' },

        },
        {
          path: '/portal/pages/fale-conosco',
          name: 'ContactosIndex',
          component: () => import('@/views/pages/Portal/Contactos/ContactosIndex.vue'),
          meta: { title: 'Contactos' },

        },
        {
          path: '/portal/pages/quem-somos',
          name: 'QuemSomos',
          component: () => import('@/views/pages/Portal/QuemSomos.vue'),
           meta: { title: 'Quem Somos' },
        },
        {
          path: '/portal/pages/lojas',
          name: 'LojasIndex',
          component: () => import('@/views/pages/Portal/Lojas/LojasIndex.vue'),
        },

         {
          path: '/portal/pages/politicas-de-privacidade',
          name: 'PoliticasPrivacidade',
          component: () => import('@/views/pages/Portal/PoliticasPrivacidade.vue'),
        },
        {
          path: '/portal/pages/categorias',
          name: 'portal-todas-as-categorias',
          component: () => import('@/views/pages/Portal/Categorias/CategoriasIndex.vue'),
        },

        {
          path: '/portal/pages/cliente/notificacoes',
          name: 'ClienteNotificacoes',
          component: () => import('@/views/pages/Portal/Cliente/Notificacoes/ClienteNotificacoes.vue'),
        },

                {
          path: '/portal/pages/cliente/meus-cartoes',
          name: 'ClienteCartoes',
          component: () => import('@/views/pages/Portal/Cliente/Cartoes/CartoesIndex.vue'),
        },

        {
          path: '/portal/pages/cliente/dashboard',
          name: 'ClienteDashboard',
          component: () => import('@/views/pages/Portal/Cliente/ClienteDashboard.vue'),
          meta: { title: 'Minha Dashboard' },
        },

        {
          path: '/portal/pages/cliente/meu-perfil',
          name: 'ClientePerfil',
          component: () => import('@/views/pages/Portal/Cliente/Perfil/ClientePerfil.vue'),
          meta: { title: 'Meu Perfil' },
        },

        {
          path: '/portal/pages/cliente/meus-favoritos',
          name: 'ClienteProdutosFavoritos',
          component: () => import('@/views/pages/Portal/Cliente/Favoritos/ClienteProdutosFavoritos.vue'),
          meta: { title: 'Meus Favoritos' },
        },

        {
          path: '/portal/pages/cliente/endereco/entrega',
          name: 'ClienteEnderecoEntrega',
          component: () => import('@/views/pages/Portal/Cliente/Endereco/ClienteEnderecoEntrega.vue'),
          meta: { title: 'Meu Endereço de Entrega' },
        },

        {
          path: '/portal/pages/cliente/meus-pedidos',
          name: 'ClientePedidos',
          component: () => import('@/views/pages/Portal/Cliente/Pedidos/ClientePedidos.vue'),
           meta: { title: 'Meus Pedidos' },
        },
        {
          path: '/portal/pages/carrinho/resumo',
          name: 'CarrinhoResumo',
          component: () => import('@/views/pages/Portal/Carrinho/CarrinhoResumo.vue'),
           meta: { title: 'Meu Carrinho' },
        },

        {
          path: '/portal/pages/produto/detalhes/:produto_id',
          name: 'ProdutoDetalhes',
          component: () => import('@/views/pages/Portal/Produtos/ProdutoDetalhes.vue'),
           meta: { title: 'Detalhes do Produto' },
        },

        {
          path: '/portal/pages/loja/:loja_id',
          name: 'detalhes-da-loja',
          component: () => import('@/views/pages/Portal/Lojas/LojaDetalhes.vue'),
        },

        {
          path: '/portal/page/produtos/pesquisar/:keyword',
          name: 'resultados-da-pesquisa',
          component: () => import('@/views/pages/Portal/Produtos/ResultadosDaPesquisa.vue'),
           meta: { title: 'Resultados da Pesquisa' },
        },

                {
          path: '/portal/page/produtos/pesquisa/:keyword',
          name: 'resultado-da-pesquisa',
          component: () => import('@/views/pages/Portal/Produtos/ResultadoDaPesquisa.vue'),
           meta: { title: 'Resultados da Pesquisa' },
        },
        {
          path: '/portal/page/produtos/categoria/:uuid',
          name: 'ProdutosPorCategoria',
          component: () => import('@/views/pages/Portal/Produtos/ProdutosPorCategoria.vue'),
           meta: { title: 'Produtos da Categoria' },
        },

        {
          path: '/portal/page/produtos/em-destaque',
          name: 'ProdutosEmDestaque',
          component: () => import('@/views/pages/Portal/Produtos/ProdutosEmDestaque.vue'),
           meta: { title: 'Produtos Em Destaque' },
        },

                {
          path: '/portal/page/produtos/mais-vendidos',
          name: 'ProdutosMaisVendidos',
          component: () => import('@/views/pages/Portal/Produtos/ProdutosMaisVendidos.vue'),
         meta: { title: 'Produtos Mais Vendidos' },

      },
        {
          path: '/vendor-store',
          name: 'VendorStore',
          component: () => import('@/views/vendor/VendorStore.vue'),
        },
        {
          path: '/search-product',
          name: 'SearchProduct',
          component: () => import('@/views/shop/SearchProduct.vue'),
        },
        {
          path: '/single-product',
          name: 'SingleProduct',
          component: () => import('@/views/shop/SingleProduct.vue'),
        },
        {
          path: '/cart',
          name: 'Cart',
          component: () => import('@/views/shop/Cart.vue'),
        },
        {
          path: '/checkout-alternative',
          name: 'CheckoutAlternative',
          component: () => import('@/views/shop/CheckoutAlternative.vue'),
        },
        {
          path: '/vendor-order-list',
          name: 'VendorOrderList',
          component: () => import('@/views/VendorOrderList.vue'),
        },
        {
          path: '/order-list',
          name: 'OrderList',
          component: () => import('@/views/Orders/OrderList.vue'),
        },
        {
          path: '/order-details',
          name: 'OrderDetails',
          component: () => import('@/views/Orders/OrderDetails.vue'),
        },
        {
          path: '/list-address',
          name: 'ListAddress',
          component: () => import('@/views/address/ListAddress.vue'),
        },
        {
          path: '/add-address',
          name: 'AddAddress',
          component: () => import('@/views/address/AddAddress.vue'),
        },
        {
          path: '/view-profile',
          name: 'ViewProfile',
          component: () => import('@/views/profile/ViewProfile.vue'),
        },
        {
          path: '/edit-profile',
          name: 'EditProfile',
          component: () => import('@/views/profile/EditProfile.vue'),
        },
        {
          path: '/all-tickets',
          name: 'AllTickets',
          component: () => import('@/views/AllTickets.vue'),
        },
        {
          path: '/ticket-details',
          name: 'TicketDetails',
          component: () => import('@/views/TicketDetails.vue'),
        },
        {
          path: '/wishlist',
          name: 'Wishlist',
          component: () => import('@/views/Wishlist.vue'),
        },
        {
          path: '/product-list',
          name: 'ProductList',
          component: () => import('@/views/ProductList.vue'),
        },
        {
          path: '/edit-product',
          name: 'EditProduct',
          component: () => import('@/views/EditProduct.vue'),
        },
        {
          path: '/profile',
          name: 'Profile',
          component: () => import('@/views/Profile.vue'),
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: () => import('@/views/Dashboard.vue'),
        },

        {
          path: '/test',
          name: 'Test',
          component: () => import('@/views/test'),
        },
        {
          path: '/test-2',
          name: 'Test 2',
          component: () => import('@/views/test-2'),
        },
      ],
    },

    {
      path: '/sign-up',
      name: 'Sign Up',
      component: () => import('@/views/SignUp.vue'),
       meta: { title: 'Criar Conta' },

    },
    {
      path: '/landing',
      name: 'Landing',
      component: () => import('@/views/Landing.vue'),
    },
    {
      path: '/mobile-categories',
      name: 'Mobile Categories',
      component: () => import('@/views/MobileCategories.vue'),
    },
  ],
})

// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title || 'Your Default Title';
//   next();
// || 'Your Default Title';
// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title || 'Your Default Title'
//   next()
// })
// });
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - ${process.env.VUE_APP_TITLE}`
  next()
})
export default router
