import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'

import axios from 'axios'
import VueAxios from 'vue-axios'
// var cors = require('cors')

import { store } from './store/store'
import './plugins'
import '@/assets/scss/_global.scss'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

import shareIt from 'vue-share-it'
import VueMeta from 'vue-meta'
// plugin svgrender
// import Vue plugin
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'

// import polyfills for IE if you want to support it
import 'vue-svg-inline-plugin/src/polyfills'

// end:: svgrender

import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  import VueApexCharts from 'vue-apexcharts'
Vue.use(shareIt)
Vue.use(VueMeta)
Vue.config.productionTip = false

const token = localStorage.getItem('TOKEN')

if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('TOKEN')}`
  axios.defaults.headers.common['Content-Type'] = 'application/json'
}

// if(token){
//   axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("TOKEN")}`
// }
// if (token) {
//   axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('TOKEN')}`
// }

Vue.use(Viewer)
// use Vue plugin without options
Vue.use(VueSvgInlinePlugin)
Vue.use(VueAxios, axios)
// axios.defaults.withCredentials = true;
// use Vue plugin with options
VueSvgInlinePlugin.install(Vue, {
	attributes: {
		data: ['src'],
		remove: ['alt'],
	},
})

  Vue.component(VueSlickCarousel)
  Vue.component('VueSlickCarousel', VueSlickCarousel)
  Vue.use(VueApexCharts)

  // set global axios like this
Vue.prototype.$axios = axios

  Vue.component('apexchart', VueApexCharts)
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
